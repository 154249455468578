document.addEventListener( 'auth:login', ( event ) => {
    if( window.Application )
    {
        window.Application.update( { user: event.detail.user } );
    }
} );

document.addEventListener( 'auth:reset', ( event ) => {
    console.log( 'auth:reset', event );
} );

document.addEventListener( 'account:show', ( event ) => {
    if( window.Application )
    {
        if( window.Application.state.context == 'visitor' || window.Application.state.context.trim() == '' )
        {
            if( window.AuthenticationPanel )
            {
                window.AuthenticationPanel.setState( { visible: true, restart: (event.detail && event.detail.restart ) ? true : false } );
            }
            return;
        }
        else
        {
            if( window.AccountPanel )
            {
                window.AccountPanel.loadCurrentAccount();
            }
        }
    }
} );