import React, { useState } from "react";
import {Dialog} from 'primereact/dialog';
import GenericUI from "../generic.ui";
import {Translator} from "../../translator";
import {AuthenticationAPI} from "../../../api/authentication";

//import "./styles/smartphone.css";
//import "./styles/tablet.css";
import "./styles/desktop.css";

function changeEmail(e)
{
    window.AuthenticationPanel.setState( { email: e.target.value } );
}

function changePassword(e)
{
    window.AuthenticationPanel.setState({password: e.target.value } );
}

function loginUser()
{
    AuthenticationAPI.authLogin()
        .then((response) => {
            if( response )
            {
                window.open('index.html', '_self');
            }
        })
        .catch( (error ) => {
            if( window.Messenger )
            {
                window.Messenger.showMessage( 'error', error );
            }

        });
}

export default class AuthenticationPanel extends GenericUI
{
    constructor( props ) {
        super( props );
        this.state = {
            email: ( props.user && props.user.email ) ? props.user.email : '',
            password: ( props.user && props.user.password ) ? props.user.password : '',
            context: props.context,
            user: props.user,
            token: props.token,
            visible: ( props.context === 'authentication' ) ? true : false,
            restart: false
        };
    }

    componentDidMount()
    {
        window.AuthenticationPanel = this;
    }

    hide()
    {
        if( this.state.restart )
        {
            window.open('index.html', '_self');
        }
        this.setState( { visible: false, restart: false } );
    }
    
    handleKeyDown(e) {
        if( e.key === "Escape" )
        {
            this.hide();
        }

        if( e.key === "Enter" )
        {
            loginUser();
        }
    }

    render()
    {
        super.render();

        if( this.state.visible )
        {
            return(
                <div className="mask">
                    <Dialog
                        modal
                        visible={this.state.visible}
                        onHide={() => this.hide() }
                        header={Translator.get("authentication")}
                        className="auth-panel"
                        closable={false}
                    >

                        <div className="auth-email-field">
                            <label htmlFor="fld_email">{Translator.get("email")}</label>
                            <input onKeyDown={ ( e ) => { this.handleKeyDown(e) } } type="email" autoComplete="email" id="fld_email" name="fld_email" placeholder={Translator.get('enter_your_email')} defaultValue={ this.state.email } onChange={ changeEmail } />
                        </div>

                        <div className="auth-password-field">
                            <label htmlFor="fld_password">{Translator.get("password")}</label>
                            <input onKeyDown={ ( e ) => { this.handleKeyDown(e) } } type="password" autoComplete="current-password" id="fld_password" name="fld_password" placeholder={Translator.get('enter_your_password')} defaultValue={ this.state.password } onChange={ changePassword } />
                        </div>

                        <div className="auth-remember-field">
                            <input disabled style={{cursor: "not-allowed"}} type="checkbox" id="remember" name="remember"/>
                            <label style={{cursor: "not-allowed"}} htmlFor="remember">{Translator.get("remember_me")}</label>
                        </div>

                        <div className="auth-buttons">
                            <button onClick={AuthenticationAPI.authRegister} className="btn-create" id="auth-btn-register">{Translator.get("create_an_account")}</button>
                            <button onClick={loginUser} id="auth-btn-click" className="btn-special">{Translator.get("login")}</button>
                        </div>
                    </Dialog>
                </div>
            );
        }
        else
        {
            return null;
        
        }
    }
}