import { AuthenticationAPI } from '../authentication/index.js';
import {NetUtils} from '../../classes/utils/net.utils.js';
import {baseUrl} from '../config.js';

export const TranspilerAPI = {

    transpile: function( project_name, file_name, publish )
    {
        var p = new Promise( (resolve, reject) => {
            var StartTranspile = Date.now();
            const checkTranspiler = () => {
                // get the IP address of the current user
                NetUtils.getIPAddress()
                // if the IP address is retrieved successfully
                .then( ( ip ) => {        
                    var token = AuthenticationAPI.getLocaleToken();
                    if( token ==undefined || token == null )
                    {
                        reject( "invalid_token" );
                    }
                    var xhr = new XMLHttpRequest();
                    xhr.open("POST", baseUrl + "/transpile/check", true );
                    xhr.setRequestHeader("Content-Type", "application/json");
                    xhr.setRequestHeader( 'x-forwarded-for', ip  );
                    xhr.setRequestHeader( 'Accept', 'application/json' );
                    xhr.setRequestHeader( 'token', token );
                    xhr.setRequestHeader( 'project_name', project_name);
                    xhr.setRequestHeader( 'file_name', file_name);
                    xhr.setRequestHeader( 'mode', 'dev' );
                    xhr.onload = function()
                    {
                        if( xhr.status == 200 )
                        {
                            var json = JSON.parse( xhr.responseText );
                            if( json )
                            {
                                if( json.err )
                                {
                                    if( json.msgErr != 'noLog' )
                                    {
                                        if( Date.now() - StartTranspile > 300000 )
                                        {
                                            reject( 'building_timeout' );
                                        }
                                        else
                                        {
                                            if( window.buildCancelled )
                                            {
                                                reject( 'building_cancelled' );
                                            }
                                            else
                                            {
                                                setTimeout( checkTranspiler, 1000 );
                                            }
                                        }
                                    }
                                    else
                                    {
                                        reject( json.msgErr );
                                    }
                                }
                                else
                                {
                                    if( json.data && json.data.message )
                                    {
                                        resolve( json.data.message );
                                    }
                                    else
                                    {
                                        setTimeout( checkTranspiler, 1000 );
                                    }
                                }
                            }
                            else
                            {
                                setTimeout( checkTranspiler, 1000 );
                            }
                        }
                    }
                    xhr.send();
                })
                .catch( ( error ) => { reject( error ); } );
            };

            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("POST", baseUrl + "/transpile/project", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'project_name', project_name);
                xhr.setRequestHeader( 'file_name', file_name);
                xhr.setRequestHeader( 'publish', publish );                
                xhr.setRequestHeader( 'mode', 'dev' );
        
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.msgError );
                        }
                        else{
                            checkTranspiler();
                        }
                        /**
                        var json = JSON.parse( xhr.responseText );
                        if( json.err )
                        {
                            reject( json.data.message );
                        }

                        if( json.data && json.data.message )
                        {
                            resolve( json.data.message );
                        }
*/
                    } else {
                        console.dir("transpile/project ERROR STATUS: " + xhr.status);
                        // TODO: format error
                        reject (xhr);
                    }
                }
                xhr.ontimeout = _ => {
                    console.dir("transpile/project TIMEOUT");
                    // TODO: format error
                    reject ("timeout")
                }
                xhr.onerror = (e) => {
                    console.dir("transpile/project ERROR", e);
                    // TODO: format error
                    reject (e.responseText);
                }
                xhr.onabort = (e) => {
                    console.dir("transpile/project ABORT", e);
                    // TODO: format error
                    reject (e.responseText);
                }

                xhr.send();
            })
            // if the IP address is not retrieved successfully
            .catch( ( error ) => { reject( error ); } );
        } );
        return p;
    },

    publish: function( project_name, file_name )
    {
        var p = new Promise( (resolve, reject) => {
            // get the IP address of the current user
            NetUtils.getIPAddress()
            // if the IP address is retrieved successfully
            .then( ( ip ) => {        
                var token = AuthenticationAPI.getLocaleToken();
                if( token ==undefined || token == null )
                {
                    reject( "invalid_token" );
                }
                var xhr = new XMLHttpRequest();
                xhr.open("POST", baseUrl + "/transpile/publish", true );
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader( 'x-forwarded-for', ip  );
                xhr.setRequestHeader( 'Accept', 'application/json' );
                xhr.setRequestHeader( 'token', token );
                xhr.setRequestHeader( 'project_name', project_name);
                xhr.setRequestHeader( 'file_name', file_name);
                xhr.onload = function()
                {
                    if( xhr.status == 200 )
                    {
                        var json = JSON.parse( xhr.responseText );
                        if( json )
                        {
                            if( json.err )
                            {
                                reject( json.msgErr );
                            }
                            else
                            {
                                if( json.data && json.data )
                                {
                                    resolve( json.data );
                                }
                            }
                        }
                        reject( "error" );
                    } else {
                        console.dir("transpile/project ERROR STATUS: " + xhr.status);
                        // TODO: format error                        
                        reject (xhr);
                    }
                }
                xhr.ontimeout = _ => {
                    console.dir("transpile/project TIMEOUT");
                    // TODO: format error                    
                    reject ("timeout");
                }
                xhr.onerror = (e) => {
                    console.dir("transpile/project ERROR", e);
                    // TODO: format error                    
                    reject (e.responseText);
                }
                xhr.onabort = (e) => {
                    console.dir("transpile/project ABORT", e);
                    // TODO: format error
                    reject (e.responseText);
                }
                xhr.send();
            })
            .catch( ( error ) => { reject( error ); } );
        } );
        return p;
    }
};