import GenericUI from '../generic.ui.js';
import { Translator } from '../../translator/index.js';
import Devices from '../devices';
import { TranspilerAPI } from '../../../api/transpiler/index.js';
import {AuthenticationAPI} from '../../../api/authentication/index.js';
import {ProjectAPI} from '../../../api/project/index.js';
import QRCode from 'qrcode';
import { baseUrl, localHost } from '../../../api/config.js';
import { pixelValue } from '../../utils/css.utils.js';

// CSS UI
import './styles/desktop.css';
//import './styles/smartphone.css';
//import './styles/tablet.css';

export default class ToolBar extends GenericUI {

    

    constructor( props ) {      
        super( props );
        this.fullEditor = false;
        this.fullScreen = false;
        this.panelsPresetMode = false;
        this.panelWidths = [];
    }
    
    componentDidMount()
    {
        window.ToolBar = this;
    }

    toggleFullEditor()
    {
        if( window.ToolBar )
        {
            window.ToolBar.fullEditor = !window.ToolBar.fullEditor;
            if( !window.ToolBar.fullEditor )
            {
                /*document.querySelector('.projects-tree').style.display='block';
                document.querySelector('.aidocpanel').style.display='block';
                document.querySelector('.code-editor-container').style.left=document.querySelector('.projects-tree').style.width;
                document.querySelector('.code-editor-container').style.right=document.querySelector('.aidocpanel').style.left;*/

                window.ProjectsTree?.resize(window.ToolBar.panelWidths[0]);
                window.AIDocPanel?.resize(window.ToolBar.panelWidths[1]);
            }
            else
            {
                window.ToolBar.panelWidths[0] = pixelValue(getComputedStyle(document.querySelector('.projects-tree')).width);
                window.ToolBar.panelWidths[1] = pixelValue(getComputedStyle(document.querySelector('.aidocpanel')).width);

                window.ProjectsTree?.resize({size: 0, force:true}, undefined,false);
                window.AIDocPanel?.resize({size: 1, force:true}, undefined,false);
                /*document.querySelector('.projects-tree').style.display='none';
                document.querySelector('.aidocpanel').style.display='none';
                document.querySelector('.code-editor-container').style.left='0px';
                document.querySelector('.code-editor-container').style.right='0px';                */
            }
        }
    }

    toggleFullScreen() {
        if( window.ToolBar )
        {
            window.ToolBar.fullScreen = !window.ToolBar.fullScreen;
            if( window.ToolBar.fullScreen )
            {
                document.documentElement.requestFullscreen();
            }
            else
            {
                document.exitFullscreen();
            }
        }
    }

    panelsPreset() {
        if ( window.ToolBar ) {
            window.ToolBar.panelsPresetMode = !window.ToolBar.panelsPresetMode;
            if (window.ToolBar.panelsPresetMode) {
                window.ToolBar.panelWidths[2] = pixelValue(getComputedStyle(document.querySelector('.projects-tree')).width);
                window.ToolBar.panelWidths[3] = pixelValue(getComputedStyle(document.querySelector('.aidocpanel')).width);

                window.AIDocPanel?.resize(0.44, true);
                window.ProjectsTree?.resize(0.12, true);
            } else {
                window.ProjectsTree?.resize(window.ToolBar.panelWidths[2]);
                window.AIDocPanel?.resize(window.ToolBar.panelWidths[3]);
            }
        }
    }

    build( action )
    {
        if( window.CodeEditor )
        {
            if( window.CodeEditor.state.tabs.length > 0 && window.CodeEditor.state.selectedTab && window.CodeEditor.state.selectedTab.trim() != '' )
            {
                if( window.CodeEditor.state.path && window.contents[window.CodeEditor.state.path] )
                {
                    ProjectAPI.saveFile( window.CodeEditor.state.path, window.contents[window.CodeEditor.state.selectedTab])
                        .then( (response) => {
                            if( window.TabBar )
                            {
                                window.TabBar.modifiedTab( window.CodeEditor.state.path, false );
                            }
                        })
                        .catch( (error) => {
                            if(window.Messenger)
                            {
                                window.Messenger.showMessage( 'error', error );
                                return;
                            }
                        });
                }
            }
        }

        if( window.LoaderAnim )
        {
            window.buildCancelled = false;
            if( action  == 'publish' )
            {
                window.LoaderAnim.show( 'build', Translator.get( 'preparing_publishing' ) );
            }
            else
            {
                if( action == 'run' )
                {
                    window.LoaderAnim.show( 'build', Translator.get( 'build_and_run' ) );
                }
            }

            if( window.CodeEditor )
            {
                var path = window.CodeEditor.state.path;
                if(path.startsWith( '/' ))
                {
                    path = path.substring(1);
                }
                var parts = path.split( '/' );
                let file_name = parts[parts.length-1];
                parts = parts.slice( 0, parts.length - 1 );
                var project_name = parts.join( '/' );
                
                if( window.MessagesPanel )
                {
                    window.MessagesPanel.setState( { /*visible: true,*/ messages: [], nbErrors:0, nbWarnings:0 } );
                }

                var self = this;
                TranspilerAPI.transpile( project_name, file_name, ( action == 'publish' ) )
                    .then( ( messages ) => {
                        console.log("success", messages);
                        window.LoaderAnim.hide();
                        let nbErrors = 0;
                        let nbWarnings = 0;
                        let firstError = -1;
                        window.CodeEditor.ai_decorations?.clear();

                        let ed = window.CodeEditor.editorRef.current;
                        if( messages && messages.length > 0 )
                        {
                            for( var m=0; m < messages.length; m++ )
                            {
                                let msg = messages[ m ];
                                let msg_text_trans;
                                
                                if ( msg.compilerWarning || msg.compilerError ) {
                                    msg.messageText = (msg.compilerError || msg.compilerWarning) + ' ' + JSON.stringify(msg);
                                } else {
                                    let msg_text = 'undefined_message';
                                    if( msg.text )
                                    {
                                        let parts = msg.text.split(':');
                                        if( parts.length > 0 )
                                        {
                                            msg_text = parts[0];
                                        }
                                    }
                                    msg_text_trans = Translator.get(msg_text);

                                    msg.messageText = msg_text_trans + ' ' + 
                                                        Translator.get('in') + ' ' + msg.path + ' ' +
                                                        Translator.get('at') + ' ' + msg.line + ', ' + 
                                                        Translator.get('column') + ' ' + msg.column;
                                }
                                let hasPos = typeof msg.line !== 'undefined' && typeof msg.column !== 'undefined';
                                let pos, word;
                                if (hasPos) {
                                    pos = { lineNumber: msg.line, column: msg.column };
                                    ed.setPosition( pos );
                                    word = ed.getModel().getWordAtPosition(pos);
                                    if (!m) ed.revealLine( pos.lineNumber );
                                }

                                msg.key = 'msg_' + m
                                if( msg.type == 'error' || msg.compilerError ) {
                                    nbErrors++;
                                    if (firstError<0) firstError=nbErrors;
                                    /* FVL: TODO
                                        - use Model markers https://microsoft.github.io/monaco-editor/playground.html?source=v0.50.0#example-extending-language-services-model-markers-example
                                        - remove markers when editing the marked word
                                        - decide what to do with the error (at least no more select+mark)
                                    */
                                    if (hasPos && word) {
                                        msg.startLineNumber = pos.lineNumber;
                                        msg.startColumn = word.startColumn;
                                        msg.endLineNumber = pos.endLineNumber;
                                        msg.endColumn = word.endColumn;
                                        msg.marker = true;
                                        
                                        let markers = [];
                                        markers.push(
                                            {
                                                message: msg_text_trans,
                                                severity: window.CodeEditor.monacoRef.current.MarkerSeverity.Error,
                                                startLineNumber: pos.lineNumber,
                                                startColumn: word.startColumn,
                                                endLineNumber: pos.lineNumber,
                                                endColumn: word.endColumn,
                                            }
                                        )
                                        window.CodeEditor.monacoRef.current.editor.setModelMarkers(ed.getModel(), msg.key, markers);
                                    }
                                } else if ( msg.type == 'warning' || msg.compilerWarning /*msg.type == 'compiler_warning'*/ )                                 {
                                    nbWarnings++;
                                }
                            }
                        }

                        if( window.StatusBar )
                        {
                            window.StatusBar.setState( { nbErrors: nbErrors, nbWarnings: nbWarnings } );
                        }

                        if( window.MessagesPanel )
                        {
                            let newState = { messages: messages, nbErrors: nbErrors, nbWarnings: nbWarnings, displayWarnings: false }
                            if (!!nbErrors) newState.visible = true;
                            window.MessagesPanel.setState( newState );
                        }

                        if( nbErrors > 0 )
                        {
                            // FVL: temp disable, too flashy for Laurant
                            // TODO: find something less intrusive
                            /*if( window.Messenger )
                            {
                                window.Messenger.showMessage( 'error', 'code_error' );
                            }*/
                           if (messages[firstError])
                                window.CodeEditor.highlightWordAtPosition(messages[firstError].line, messages[firstError].column, true);
                        }
                        else
                        {
                            if( action == 'publish' )
                            {
                                self.publish();
                            }
                            else
                            {
                                if( action == 'run' )
                                {
                                    var token = AuthenticationAPI.getLocaleToken();
                                    window.open( baseUrl +'/run?t=' + token + '/index.html', 'aoz_viewer' );
                                }
                            }
                            
                        }                        
                    })
                    .catch( ( message ) => {
                        window.LoaderAnim.hide();
                        // TODO: format error
                        console.log( message );
                        if(window.Messenger)
                        {
                            if( message != 'building_cancelled' )
                            {
                                window.Messenger.showMessage( 'error', message );
                                return;
                            }
                        }                        
                        // TODO: Display message to user
                    });
            }
            else
            {
                window.LoaderAnim.hide();
                if( window.Messenger )
                {
                    window.Messenger.showMessage( 'error', 'no_project_opened' );
                }   
            }
        }
    }

    publish()
    {
        if( window.LoaderAnim )
        {
            window.LoaderAnim.show( 'potion', Translator.get( 'publishing' ) );
        }

        if( window.CodeEditor )
        {
            var path = window.CodeEditor.state.path;
            if(path.startsWith( '/' ))
            {
                path = path.substring(1);
            }
            var parts = path.split( '/' );
            parts = parts.slice( 0, parts.length - 1 );
            var project_name = parts.join( '/' );

            var self = this;
            TranspilerAPI.publish( project_name )
                .then( ( publishSettings ) => {
                    window.LoaderAnim.hide();
                    if( publishSettings )
                    {
                        if( publishSettings.url )
                        {
                            QRCode.toDataURL( publishSettings.url )
                                .then( urlData => {
                                    window.LoaderAnim.show( 'qrcode', publishSettings.url, { imgUrl: urlData, url: publishSettings.url } );
                                } );
                        }
                    }
                })
                .catch( ( message ) => {
                    window.LoaderAnim.hide();
                    // TODO: Display message to user
                });
        }
    }

    mainCommands()
    {
        return(
            <div className="main-commands">
                <ToolItem id="cmd_run" icon="fa-light fa-play" tooltip="run_your_project" onClick={ (e) => { this.build( 'run' ) } } />
                <ToolItem id="cmd_publish" icon="fa-light fa-upload" tooltip="publish_your_project" onClick={ (e) => { this.build( 'publish' ) } } />
            </div>
        )
    }
    
    showAccount(e) {
        var event = new CustomEvent( 'account:show', { detail: {} });
        document.dispatchEvent( event );        
    }

    render() {
        super.render();
        var authIcon = 'fa-light fa-sharp fa-circle-user';
        if( window.Application )
        {
            if( window.Application.state && window.Application.state.context != 'connected' )
            {
                authIcon = 'fa-light fa-key';
            }
        }
        return(
            <div className="toolbar">
                <div className="left">
                    <div className="aoz-icon" />
                    <span className="aoz-title no-text-select">AOZ Studio</span>
                    <ToolItem icon={authIcon} tooltip="account_settings" onClick={this.showAccount}/>
                    <div className="aoz-repo no-text-select">{localHost ? 'LOCALHOST' : window.location.hostname.startsWith('editor.') ? '' : 'PRIVATE DEV'}</div>
                </div>
                <div className="center">
                    <ToolItem id="cmd_run" icon="fa-light fa-play" tooltip="run_your_project" onClick={ (e) => { this.build( 'run' ) } } />
                    <ToolItem id="cmd_publish" icon="fa-light fa-upload" tooltip="publish_your_project" onClick={ (e) => { this.build( 'publish' ) } } />
                </div>
                <div className="right">
                    <ToolItem icon="fa-light fa-columns-3" tooltip="panelsPresets" onClick={this.panelsPreset}/>
                    <ToolItem icon="fa-light fa-up-right-and-down-left-from-center" tooltip="toggle_full_editor" onClick={this.toggleFullEditor}/>
                    <ToolItem icon="fa-light fa-expand" tooltip="toggle_fullscreen"  onClick={this.toggleFullScreen}/>
                </div>
            </div>
        );
    }
}

function ToolItem( props )
{
    return (
        <div id={props.id} className="toolitem" onClick={props.onClick} disabled={props.disabled} alt={Translator.get(props.tooltip)} title={Translator.get(props.tooltip)}>
            <i className={props.icon} disabled={props.disabled}></i>
        </div>
    );
}