import GenericUI from "../generic.ui";
import {Translator} from "../../translator";
import "./styles/desktop.css";
//import "./styles/smartphone.css";
//import "./styles/tablet.css";

export default class StatusBar extends GenericUI
{

    constructor( props ) {
        super( props );
        this.state = {
            data:{
                position: {row: 1, column: 1},
                syntax: '',
                path: ''
            },
            showVisitor: false,
            nbErrors: 0,
            nbWarnings: 0
        }
    }

    componentDidMount() {
        window.StatusBar = this;
    }

    showVisitorStatus()
    {
        if( this.state.showVisitor )
        {
            return (
                <div className="visitor-message blink_me" alt={Translator.get('what_this_mode')} title={Translator.get('click_to_see_plans')} onClick={this.showPlans}>
                    {Translator.get('visitor_session')}
                </div>
            );
        }
        return null;        
    }

    showPlans()
    {
        window.open( 'https://www.aoz.studio/price', '_blank' );
    }

    showMessages(e, toggleWarnings=false)
    {
        if( window.MessagesPanel )
        {
            let newState = {visible: true };
            if (toggleWarnings) {
                if (window.MessagesPanel.state.visible) newState.displayWarnings = !window.MessagesPanel.state.displayWarnings;
                else newState.displayWarnings = true;
            }
            window.MessagesPanel.setState(newState);
        }
    }

    render()
    {
        super.render();
        return (
            <table className="statusbar no-text-select">
                <tbody>
                    <tr>
                        <td className="status-left">
                            &nbsp;
                        </td>

                        <td className="status-center">
                            {this.showVisitorStatus()}
                        </td>

                        <td className="status-right">
                            <div className="messages">
                                <div className="message-info errors" alt={Translator.get('show_messages')} title={Translator.get('show_messages')} onClick={(e) => this.showMessages(e)}>
                                    {this.state.nbErrors}
                                </div>
                                <div className="message-info warnings" alt={Translator.get('show_messages')} title={Translator.get('show_messages')} onClick={(e) => this.showMessages(e, true)}>
                                    {this.state.nbWarnings}
                                </div>
                            </div>
                            <div id="position" className="position">
                                    {Translator.get('position_row')} {this.state.data.position.row}, {Translator.get('position_column')} {this.state.data.position.column}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}


