var eventBus = document;
var lastItemSelected = undefined;


export function collapseAll( items, props )
{
    var newItems = [];
    for( var i = 0; i < items.length; i++ )
    {
        items[i].expanded = false;
        if( items[i].children )
        {
            collapseAll( items[i].children, props );
        }
        newItems.push(items[i]);
    }
    var event = new CustomEvent('treeview:itemsChanged', {
        detail: {
            items: newItems
        }
    } );
    eventBus.dispatchEvent( event );
    if( props.onCollapseAll )
    {
        props.onCollapseAll();
    }
}

export function expandAll( items, props )
{
    var newItems = [];
    for( var i = 0; i < items.length; i++ )
    {
        items[i].expanded = true;
        if( items[i].children )
        {
            expandAll(items[i].children, props);
        }
        newItems.push(items[i]);
    }
    var event = new CustomEvent('treeview:itemsChanged', {
        detail: {
            items: newItems
        }
    });
    eventBus.dispatchEvent(event);
    if( props.onExpandAll )
    {
        props.onExpandAll();
    }
}

export function TreeItem( item, selectedItem, props )
{
    if( item.children && item.children.length > 0  )
    {
        if( item.parent == '.' ){
            return (
                getProjectFolder( item, selectedItem, props )
            )
        }
        else
        {
            return (
                getFolder( item, selectedItem, props )
            )
        }
    }
    else
    {
        if( item.icon != 'icon_folder')
        {
            return (
                getFile( item, selectedItem, props )
            )
        }
        else
        {
            return (
                getFolder( item, selectedItem, props )
            )
        }
    }
}

function getProjectFolder( item, selectedItem, props )
{
    var icon = "";
    var onClick = () => {};
    var onContextMenu = (e) => { 
        selectItem(item);
        if ( props.onContextMenu ) {
            props.onContextMenu(e, item, props)
        }
        return false;
    };

    if( item.expanded )
    {
        icon = 'fa-solid fa-caret-down';
        onClick = () => {
            item.expanded = false;
            eventBus.dispatchEvent(new CustomEvent('treeview:itemCollapsed', {
                detail: {
                    item: item
                }
            }));
        };
    }
    else
    {
        icon = 'fa-solid fa-caret-right';
        onClick = () => {
            item.expanded = true;
            eventBus.dispatchEvent(new CustomEvent('treeview:itemExpanded', {
                detail: {
                    item: item
                }
            }));
        };
    }

    return (
        <li key={item.key} id={item.key} alt={item.label} title={item.label}>
            <i className={icon} onContextMenu={ onContextMenu } onClick={() => {
                    selectItem( item );
                    item.expanded = !item.expanded;
                }}></i>
            <div className={`treeitem-icon ${item.icon}`} onContextMenu={ onContextMenu } onClick={() => {
                    selectItem( item );
                    item.expanded = !item.expanded;
                }}></div>
            { item.edit ?
                <input id={`f_${item.key}`} type="text" item={item} defaultValue={item.label} autoFocus onKeyPress={ (e) => { preValidateItem( e, item, props.items, props ) } } onBlur={ (e) => {
                    validateItem( item, props.items, props );
                } }/> :
                <span className={( selectedItem && selectedItem.key === item.key ) ? 'selected' : ''} onClick={() => {
                    selectItem( item );
                    item.expanded = !item.expanded;
                }} onContextMenu={ onContextMenu }>{item.label}</span>
            }
            
            { item.expanded ?
                <ul>
                    {item.children.map((child, index) => (
                        TreeItem(child, selectedItem, props)
                    ))}
                </ul> : null
            }
        </li>
    );
}

function getFolder( item, selectedItem, props )
{
    var icon = "";
    var onClick = () => {};
    var onContextMenu = (e) => { 
        selectItem(item);
        if ( props.onContextMenu ) {
            props.onContextMenu(e, item, props)
        }
        return false;
    };

    if( item.expanded )
    {
        icon = 'fa-solid fa-caret-down';
        onClick = () => {
            item.expanded = false;
            eventBus.dispatchEvent(new CustomEvent('treeview:itemCollapsed', {
                detail: {
                    item: item
                }
            }));
        };
    }
    else
    {
        icon = 'fa-solid fa-caret-right';
        onClick = () => {
            item.expanded = true;
            eventBus.dispatchEvent(new CustomEvent('treeview:itemExpanded', {
                detail: {
                    item: item
                }
            }));
        };
    }

    return (
        <li key={item.key} id={item.key} alt={item.label} title={item.label}>
            <i className={icon} onContextMenu={ onContextMenu } onClick={() => {
                    selectItem( item );
                    item.expanded = !item.expanded;
                }} onDoubleClick={ (e) => { selectItem( item ); dblClickItem(e,item,props) }}></i>
            <div className={`treeitem-icon ${item.icon}`} onContextMenu={ onContextMenu } onClick={() => {
                    selectItem( item );
                    item.expanded = !item.expanded;
                }} onDoubleClick={ (e) => { selectItem( item ); dblClickItem(e,item,props) }}></div>
            { item.edit ?
                <input id={`f_${item.key}`} type="text" item={item} defaultValue={item.label} autoFocus onKeyPress={ (e) => { preValidateItem( e, item, props.items, props ) } } onBlur={ (e) => {
                    validateItem( item, props.items, props );
                } }/> :
                <span className={( selectedItem && selectedItem.key === item.key ) ? 'selected' : ''}  onClick={() => {
                    selectItem( item );
                    item.expanded = !item.expanded;
                }} onContextMenu={ onContextMenu } onDoubleClick={ (e) => { selectItem( item ); dblClickItem(e,item,props) }}>{item.label}</span>
            }
            { item.expanded ?
                <ul>
                    {item.children.map((child, index) => (
                        TreeItem(child, selectedItem, props)
                    ))}
                </ul> : null
            }
        </li>
    );
}

function getFile( item, selectedItem, props )
{
    var onContextMenu = (e) => {
        selectItem(item);
        if ( props.onContextMenu ){
            props.onContextMenu(e, item, props)
        }
        return false;
    };
    return(
        <li key={item.key} id={item.key} alt={item.label} title={item.label}>
            <div className={`treeitem-icon ${item.icon}`} onClick={( e ) => {
                    selectItem( item ); clickItem( e, item, props );
                }} onContextMenu={ onContextMenu } onDoubleClick={ (e) => { selectItem( item ); dblClickItem(e,item,props) }}></div>
            { item.edit ?
                <input id={`f_${item.key}`} type="text" item={item} defaultValue={item.label} autoFocus onKeyPress={ (e) => { preValidateItem( e, item, props.items, props ) } } onBlur={ (e) => {
                    validateItem( item, props.items, props );
                } } /> :
                <span className={( selectedItem && selectedItem.key === item.key ) ? 'selected' : ''}  onClick={( e ) => {
                    selectItem( item ); clickItem( e, item, props );
                }} onContextMenu={ onContextMenu } onDoubleClick={ (e) => { selectItem( item ); dblClickItem(e,item,props) }}>{item.label}</span>
            }
        </li>
    )
}

function clickItem( e, item, props )
{
    if( props.onClick )
    {
        props.onClick( e, item );
    }
}

function dblClickItem( e, item, props )
{
    if( props.onDoubleClick )
    {
        props.onDoubleClick( e, item );
    }
}

export function selectItem( item )
{
    if( lastItemSelected )
    {
        lastItemSelected.edit = false;
    }
    lastItemSelected = item;
    eventBus.dispatchEvent(new CustomEvent('treeview:itemSelected', {
        detail: {
            item: item
        }
    }));
}

export function getTreeHeaders( props )
{
    if( props.noHeaders )
    {
        return null;
    }
    else
    {
        return (
            <div className="treeview-header">
                { getFiltersInput( props ) }
                { getToolbar( props ) }
            </div>
        );
    }
}

function getFiltersInput( props )
{
    if( props.noFilters )
    {
        return null;
    }
    else{
        return (
            <div className="tree-filters">
                <input id="tree_filters_input" className="tree-filters-input" type="text" placeholder={props.filterPlaceholder} defaultValue="" onChange={ ( e ) => { searchResults( e.target.value ); } }/>
                <i className="tree-filters-icon fa-solid fa-search"></i>
            </div>
        );
    }
}

function getToolbar( props )
{
    if( props.noToolBar )
    {
        return null;
    }
    else{
        return (
            <div className="tree-toolbar">
                { getToolButtons( props ) }
            </div>
        );
    }
}

function getToolButtons( props)
{
    if( props.tools )
    {
        return props.tools.map((tool, index) => (
            ToolButton( tool, props )
        ));
    }
    else
    {
        return null;
    }
}

function ToolButton( tool, props )
{
    if( tool.separator )
        {
            return (
                <div key={tool.key} className="tree-tool-separator"></div>
            )
    }
    else
    {
        return (
            <div key={tool.key} className="tree-tool-button" onClick={ () => { tool.action(); } } alt={ tool.label }  title={ tool.label }>
                <i className={tool.icon}></i>
            </div>
        )
    }
}

function searchResults( value )
{
    var items = document.querySelectorAll('.treeview ul li');
    for( var i = 0; i < items.length; i++ )
    {
        if( items[i].innerText.toLowerCase().includes(value.toLowerCase()) )
        {
            items[i].style.display = 'block';
        }
        else
        {
            items[i].style.display = 'none';
        }
    }
}

export function getTreeFooters( props )
{
    if( props.noFooters )
    {
        return null;
    }
    else
    {
        return (
            <div className="treeview-footer">
                {(props.forFooters) ? props.forFooters() : null}
            </div>
        );
    }
}

export function getTreeItems( items, selectedItem, props, defMsg )
{
    if( items )
    {
        return items.map((item, index) => (
            TreeItem( item, selectedItem, props )
        ));
    }
    else
    {
        return <li>{defMsg}</li>
    }
}

export function deleteItem( item, items, props )
{
    var newItems = [];
    for( var i = 0; i < items.length; i++ )
    {
        if( items[i] !== item )
        {
            newItems.push(items[i]);
        }
    }
    var event = new CustomEvent('treeview:itemsChanged', {
        detail: {
            items: newItems
        }
    });
    eventBus.dispatchEvent(event);
}

export function addItem( item, items, props )
{
    var newItems = [];
    for( var i = 0; i < items.length; i++ )
    {
        newItems.push(items[i]);
    }
    newItems.push(item);
    var event = new CustomEvent('treeview:itemsChanged', {
        detail: {
            items: newItems
        }
    });
    eventBus.dispatchEvent(event);
}

export function editItem( item, items, props ) {
    item.edit = true;
    var newItems = [];
    for( var i = 0; i < items.length; i++ )
    {
        if( items[i].key === item.key )
        {
            newItems.push(item);
        }
        else
        {
            newItems.push(items[i]);
        }
    }
    var event = new CustomEvent('treeview:itemsChanged', {
        detail: {
            items: newItems
        }
    });
    eventBus.dispatchEvent(event);
 }
 
export function preValidateItem( e, item, items, props )
{
    if( e.key === 'Enter' )
    {
        validateItem( item, items, props );
    }
}

export function validateItem( item, items, props )
{
    if( item )
    {
        if( item.edit ) {
            item.edit = false;
            item.label = document.getElementById('f_' + item.key).value;
            if( props.onUpdateItem )
            {
                props.onUpdateItem(item, items);
            }
        }
        else
        {
            item.expanded = !item.expanded;
        }

        var newItems = [];
        for( var i = 0; i < items.length; i++ )
        {
            if( items[i].key === item.key )
            {
                newItems.push(item);
            }
            else
            {
                newItems.push(items[i]);
            }
        }
        var event = new CustomEvent('treeview:itemsChanged', {
            detail: {
                items: newItems
            }
        });
        eventBus.dispatchEvent(event)        
    }
}