import GenericUI from "../generic.ui";
import { Translator } from "../../translator";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ProjectAPI } from '../../../api/project';
import { FileUtils } from "../../utils/file.utils";
import "./styles/desktop.css";
import "./styles/smartphone.css";
import "./styles/tablet.css";

export default class NewItemPanel extends GenericUI
{
    constructor( props ) {
        super( props );
        this.state = {
            visible: false,
            path: null,
            type: null,
            name: null,
            selectedFormat: null,
            formats: [
                { name: Translator.get("aoz_include"), code: '.aozinc' },
                { name: Translator.get("html_file"), code: '.html' },
                { name: Translator.get("css_file"), code: '.css' },
                { name: Translator.get("javascript_file"), code: '.js' },
                { name: Translator.get("json_file"), code: '.json' },
                { name: Translator.get("xml_file"), code: '.xml' },
                { name: Translator.get("txt_file"), code: '.txt' }
            ]
        }

    }

    componentDidMount()
    {
        window.NewItemPanel = this;
    }

    createItem()
    {
        if( this.state.name == null || this.state.name.trim() == "" )
        {
            if( window.Messenger )
            {
                window.Messenger.showMessage( 'error', "enter_" + this.state.type + "_name" );
                return;
            }
        }

        if( this.state.name.indexOf( '/' ) >= 0
            || this.state.name.indexOf( '\\' ) >= 0
            || this.state.name.indexOf( '.' ) >= 0
            || this.state.name.indexOf( ':' ) >= 0
            || this.state.name.indexOf( '*' ) >= 0
            || this.state.name.indexOf( '?' ) >= 0
            || this.state.name.indexOf( '"' ) >= 0
            || this.state.name.indexOf( '<' ) >= 0
            || this.state.name.indexOf( '>' ) >= 0
            || this.state.name.indexOf( '|' ) >= 0 
        )
        {
            if( window.Messenger )
            {
                window.Messenger.showMessage( 'error', "invalid_" + this.state.type + "_name" );
                return;
            }
        }


        if( this.state.type === "file" && ( this.state.selectedFormat == null || this.state.selectedFormat.code.trim() == "" ) )
        {
            if( window.Messenger )
            {
                window.Messenger.showMessage( 'error', "select_file_format" );
                return;
            }
        }

        var self = this;
        var nodes = document.querySelectorAll( '.treeview-content .selected' );
        var node = undefined;
        var path = undefined;

        if( nodes && nodes.length > 0 )
        {
            node = nodes[0].parentNode;
        }
        
        if( node )
        {
            path = node.getAttribute( 'id' );
        }

        if( node.querySelector( '.icon_folder' ) == undefined && node.querySelector( '.icon_project' ) == undefined && node.querySelector( '.icon_folder_assets' ) == undefined )
        {
            path = FileUtils.dirname( path );
        }

        ProjectAPI.createItem( path, this.state.type, this.state.name, (this.state.selectedFormat) ? this.state.selectedFormat.code : '' )
            .then( (response) => {
                if( response.status === "done" )
                {
                    self.setState( { visible: false } );
                    if( window.ProjectsTree )
                    {
                        let fileItem;
                        if (this.state.type == "file") {
                            fileItem = fileItem = {

                                'key': path + '/' + this.state.name + this.state.selectedFormat.code,
                                'parent': path,
                                'label': this.state.name + this.state.selectedFormat.code,
                                'tooltip': this.state.name + this.state.selectedFormat.code,
                                'icon': 'icon_' + this.state.selectedFormat.code.replace( '.', '' ),
                                'language': this.state.selectedFormat.code.replace( '.', '' ),
                                'locked': false,
                                children:[]
                            };
                        }
                        window.ProjectsTree.loadProjects(true, window.ProjectsTree.getTreeState(), fileItem || window.TreeView?.state?.selectedItem, true );
                        /*if( this.state.type == "file" )
                        {
                            //var dirPath = FileUtils.dirname( path );
                            setTimeout( () => {
                                window.ProjectsTree.checkFile( null, fileItem );
                            }, 1000 );
                        }*/
                    }
                }
            } )
            .catch( (error) => {
                if( window.Messenger )
                {
                    window.Messenger.showMessage( 'error', error );
                }
        } );

    }

    handleKeyDown(e) {
        if( e.key === "Escape" )
        {
            this.setState( { visible: false } );
        }

        if( e.key === "Enter" )
        {
            this.createItem();
        }
    }

    render()
    {
        super.render();

        setTimeout( () => {
            var elm = document.querySelector( '#fld_name' );
            if( elm )
            {
                elm.focus();
            }
        },100);

        if( this.state.visible )
        {
            return(
                    <Dialog
                        modal
                        visible={this.state.visible}
                        onHide={() => this.setState( { visible: false } ) }
                        header={Translator.get("new_" + this.state.type)}
                        className="new-item-panel"
                    >
                        <div className="new-item-form">
                            <table>
                                <tbody>
                                    {
                                        this.state.type === "file" &&
                                        <tr>
                                            <td className="td-left label">{Translator.get("type-file")}</td>
                                            <td className="td-right">
                                                <Dropdown value={this.state.selectedFormat} onChange={(e) => this.setState( {selectedFormat: e.value } ) } options={this.state.formats} placeholder={Translator.get('select_file_format')} optionLabel="name" />
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td className="td-left label">{Translator.get("name")}</td>
                                        <td className="td-right"><input id="fld_name" onKeyDown={ ( e ) => { this.handleKeyDown(e) } } type="text" defaultValue="" placeholder={Translator.get("enter_" + this.state.type + "_name")} onChange={(e) => this.setState( {name: e.target.value} )} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>&nbsp;</div>
                        <div className="new-item-buttons">
                            <button onClick={(e) => this.setState({visible:false})}>{Translator.get("cancel")}</button>
                            <button onClick={(e) => this.createItem() }>{Translator.get("create")}</button>
                        </div>
                    </Dialog>
            );
        }
    }
}