import GenericUI from "../generic.ui";
import {Translator} from "../../translator";
import { Dialog } from 'primereact/dialog';
import {RadioButton} from 'primereact/radiobutton';
import { Checkbox } from "primereact/checkbox";
import {SelectButton} from 'primereact/selectbutton';
//import { showMessage } from '../messenger/func';
import { ProjectAPI } from '../../../api/project';
import {FileUtils} from "../../utils/file.utils";
import { _VERSION } from "../../../api/config";

// CSS
import "./styles/desktop.css";
//import "./styles/smartphone.css";
//import "./styles/tablet.css";
import Messenger from "../messenger";

export default class ProjectPanel extends GenericUI {

    constructor( props )
    {
        super(props);
        this.state = {
            visible: (props.visible) ? props.visible : false,
            update: false,
            topic: 0,
            appName: '',
            appAuthor: '',
            appVersion: '1.0.0',
            appCopyright: '',
            format: 0,
            resolution: 0,
            width: 1920,
            height: 1080,
            fullscreen: false,
            keepProportions: true,
            smoothing: true,
            errors: {
                appName: false,
                appAuthor: false,
                appVersion: false,
                appCopyright: false,
                width: false,
                height: false
            },
            protect: 0
        }
        this.manifest = undefined;
    }

    setManifest( manifest, update )
    {
        this.manifest = manifest;
        var format = 3;
        var resolution = 0;
        var ratio = manifest.display.width / manifest.display.height;
        if( ratio == 16 / 9 )
        {
            format = 0;
        }

        if( ratio == 16 / 10 )
        {
            format = 1;
        }

        if( ratio == 4 / 3 )
        {
            format = 2;
        }

        if( format != 3 )
        {
            var h = manifest.display.height;
            if( h == 720 )
            {
                resolution =  0;
            }

            if( h == 1080 )
            {
                resolution =  1;
            }

            if( h == 2160 )
            {
                resolution = 3;
            }

            if( h == 4320 )
            {
                resolution = 4;
            }
        }
        const ORIEN_VALUES = [ 'autodetect', 'landscape', 'portrait' ];
        if( ORIEN_VALUES.indexOf(this.manifest.display.orientation_detection) === 2 )
        {
            var w = manifest.display.width;
            manifest.display.width = manifest.display.height;
            manifest.display.height = w;
        }

        this.setState( {
            visible: true,
            update: (update) ? true : false,
            format:  format,
            resolution: resolution,
            appName: manifest.infos.applicationName,
            appAuthor: manifest.infos.author,
            appVersion: manifest.infos.version,
            appCopyright: manifest.infos.copyright,
            width: manifest.display.width,
            height: manifest.display.height,
            fullscreen: manifest.display.fullscreen,
            keepProportions: manifest.display.keepProportions,
            smoothing: manifest.display.smoothing,
            orientation: ORIEN_VALUES.indexOf(this.manifest.display.orientation_detection),
            errors: {
                appName: false,
                width: false,
                height: false
            },
            protect: (manifest.publish && manifest.publish.protect) ? 1 : 0
        } );
    }

    componentDidMount()
    {
        window.ProjectPanel = this;
    }

    show( manifest )
    {
        this.setManifest( manifest );
    }

    hide()
    {
        this.setState( {
            visible: false,
            topic: 0,
            appName: '',
            appAuthor: '',
            appVersion: '1.0.0',
            appCopyright: '',
            format: 0,
            resolution: 0,
            width: 1920,
            height: 1080,
            orientation: 0,
            fullscreen: false,
            keepProportions: true,
            smoothing: true,
            errors: {
                appName: false,
                width: false,
                height: false
            },
            protect: 0
        } );
    }

    validateProject()
    {
        var errors =  {
            appName: false,
            width: false,
            height: false
        };

        if( this.state.appName == undefined || this.state.appName.trim() == '' )
        {
            errors.appName = true ;
        }
        
        if( this.state.format == 3 )
        {
            if( this.state.width === undefined || this.state.width < 1 )
            {
                errors.width = true ;
            }
            
            if( this.state.height === undefined || this.state.height < 1 )
            {
                errors.height = true ;
            }
        }

        if( errors.appName || errors.width || errors.height )
        {
            if( window.Messenger )
            {
                window.Messenger.showMessage( 'error', 'project_values_error' );
                return;
            }
            this.setState( { errors: errors } );
            return;
        }
        else
        {
            this.manifest.infos.applicationName = this.state.appName;
            this.manifest.infos.author = this.state.appAuthor
            this.manifest.infos.version = this.state.appVersion
            this.manifest.infos.copyright = this.state.appAuthor
            this.manifest.display.fullscreen = this.state.fullscreen;
            this.manifest.display.keepProportions = this.state.keepProportions;
            this.manifest.display.smoothing = this.state.smoothing;
            if( this.manifest.publish === undefined )
            {
                this.manifest.publish = {
                    protect: false
                };
            }
            this.manifest.publish.protect = (this.state.protect == 1) ? true : false;

            const ORIEN_VALUES = [ 'autodetect', 'landscape', 'portrait' ];
            this.manifest.display.orientation_detection = ORIEN_VALUES[ this.state.orientation ];
            
            
            if( this.state.format == 3 )
            {
                this.manifest.display.width = this.state.width;
                this.manifest.display.height = this.state.height;
            }
            else
            {
                var h = 0;
                var ratio = 0;
                if( this.state.resolution == 0 )
                {
                    h = 720;
                }
                
                if( this.state.resolution == 1 )
                {
                    h = 1080;
                }

                if( this.state.resolution == 2 )
                {
                    h = 2160;
                }
                
                if( this.state.resolution == 3 )
                {
                    h = 4320;
                }
                
                if( this.state.format == 0 )
                {
                    ratio = 16 / 9;
                } 
                
                if( this.state.format == 1 )
                {
                    ratio = 16 / 10;
                }

                if( this.state.format == 2 )
                {
                    ratio = 4 / 3;
                }
                
                this.manifest.display.width = h * ratio;
                this.manifest.display.height = h;
            }

            if( this.state.orientation === 2 )
            {
                var w = this.manifest.display.width;
                this.manifest.display.width = this.manifest.display.height;
                this.manifest.display.height = w;
            }
            this.manifest.display.resolution=this.manifest.display.width + 'x' + this.manifest.display.height;

            if( this.state.update === false )
            {
                ProjectAPI.createProject( this.manifest )
                    .then( ( response ) => {
                        this.hide();
                        this.manifest = undefined;
                        if( window.ProjectsTree )
                        {
                            let state = window.ProjectsTree.getTreeState();
                            // ouvrir le parent
                            state[response.key.substring(0, response.key.lastIndexOf('/'))] = true;
                            window.ProjectsTree.loadProjects( true, state, response, true );
                            /*setTimeout( () => {
                                window.ProjectsTree.checkFile(null,response);
                            },3000);*/
                        }
                    })
                    .catch( ( error ) => {
                        console.error( error );
                        if( window.Messenger )
                        {
                            window.Messenger.showMessage( 'error', error );
                            return;
                        }
                    });
            }
            else
            {
                var nodes = document.querySelectorAll( '.treeview-content .selected' );
                var node = undefined;
                var path = undefined;
                if( nodes && nodes.length > 0 )
                {
                    node = nodes[0].parentNode;
                }
        
                if( node )
                {
                    path = node.getAttribute( 'id' );
                }
        
                if( window.LoaderAnim )
                {
                    window.LoaderAnim.show( 'spinner', Translator.get('update_project_properties') );
                }
                
                this.hide();
                ProjectAPI.updateProject( path, this.manifest )
                    .then( ( response ) => {
                        console.log( 'ici' );
                        if( window.LoaderAnim )
                        {
                            window.LoaderAnim.hide();
                        }
                        this.manifest = undefined;
                    })
                    .catch( ( error ) => {
                        console.error( error );
                        if( window.Messenger )
                        {
                            window.Messenger.showMessage( 'error', error );
                            return;
                        }
                    });
            }
        }

    }

    render()
    {
        super.render();
        if( !this.state.visible )
        {
            return null;
        }

        return (
            <div className="mask">
                <Dialog
                    modal
                    visible={this.state.visible}
                    onHide={() => this.setState( { visible: false } ) }
                    header={this.state.update === false ? Translator.get("new_project") : Translator.get("project_properties")}
                    className="project-panel"
                >
                <div className="project-panel-topics">
                    <ul>
                        <li className={(this.state.topic==0)?'selected':null} onClick={ (e) => { this.setState( { topic:0 } ) } }>
                            {Translator.get('project_infos')}
                        </li>
                        <li className={(this.state.topic==1)?'selected':null} onClick={ (e) => { this.setState( { topic:1 } ) } }>
                            {Translator.get('project_display')}
                        </li>
                        <li className={(this.state.topic==2)?'selected':null} onClick={ (e) => { this.setState( { topic:2 } ) } }>
                            {Translator.get('project_publish')}
                        </li>                       
                    </ul>
                </div>
                <div className="project-panel-content">
                    <div className="project-panel-infos" style={{display: (this.state.topic==0)?'block':'none'}}>
                        <label forhtml="project-name">* {Translator.get("project_name")}</label>
                        <input type="text" id="project-name" placeholder={Translator.get("project_name")} defaultValue={this.state.appName} onChange={(e) => this.setState( {appName: e.target.value})} />
                        <label forhtml="project-author">{Translator.get("project_author")}</label>
                        <input type="text" id="project-author" placeholder={Translator.get("project_author")} defaultValue={this.state.appAuthor} onChange={(e) => this.setState( {appAuthor: e.target.value})}/>
                        <label forhtml="project-version">{Translator.get("project_version")}</label>
                        <input type="text" id="project-version" placeholder={Translator.get("project_version")} defaultValue={this.state.appVersion} onChange={(e) => this.setState( {appVersion: e.target.value})} />
                        <label forhtml="project-copyright">{Translator.get("project_copyright")}</label>
                        <input type="text" id="project-copyright" placeholder={Translator.get("project_copyright")} defaultValue={this.state.appCopyright} onChange={(e) => this.setState( {appCopyright: e.target.value})} />
                    </div>
                    
                    <div className="project-panel-display" style={{display: (this.state.topic==1)?'block':'none'}}>
                    <label forhtml="project-resolution" style={{display: (this.state.format<3)?'inline-block':'none'}}>{Translator.get("project_resolution")}</label>
                        <SelectButton id="project-resolution" optionLabel="name" style={{display: (this.state.format<3)?'inline-flex':'none'}} value={this.state.resolution} options={[
                            { name: 'HD', value: 0 },
                            { name: 'Full HD', value: 1 },
                            { name: '4K', value: 2 },
                            { name: '8K', value: 3 }
                        ]} onChange={(e) => this.setState({resolution: e.target.value})} />
                        <label forhtml="project-size" style={{display: (this.state.format == 3)?'inline-block':'none'}}>* {Translator.get("project_size")}</label>
                        <input type="number" id="project-width" style={{display: (this.state.format == 3)?'inline-block':'none'}} value={this.state.width} placeholder={Translator.get("project_width")}  onChange={(e) => this.setState( {width: e.target.value})}/>
                        <span style={{display: (this.state.format == 3)?'inline-block':'none'}}>px&nbsp;</span>
                        <input type="number" id="project-height" style={{display: (this.state.format == 3)?'inline-block':'none'}} value={this.state.height} placeholder={Translator.get("project_height")} onChange={(e) => this.setState( {height: e.target.value})} />
                        <span style={{display: (this.state.format == 3)?'inline-block':'none'}}>px</span>
                        < br />&nbsp;<br />
                        <label forhtml="project-format" style={{verticalAlign: "top"}}>{Translator.get("project_format")}</label>
                        <SelectButton id="project-format" optionLabel="name" value={this.state.format} options={[
                            { name: '16:9', value: 0 },
                            { name: '16:10', value: 1 },
                            { name: '4:3', value: 2 },
                            { name: Translator.get('project_custom'), value: 3 }
                        ]} onChange={(e) => this.setState({format: e.target.value})} />
                        < br />&nbsp;<br />
                        <label forhtml="project-orientation">{Translator.get("project_orientation")}</label>
                        <SelectButton id="project-orientation" optionLabel="name" value={this.state.orientation} options={[
                            { name: Translator.get('project_autodetect'), value: 0 },
                            { name: Translator.get('project_landscape'), value: 1 },
                            { name: Translator.get('project_portrait'), value: 2 }
                        ]} onChange={(e) => this.setState({orientation: e.target.value})} />                        
                        < br />&nbsp;<br />
                        <Checkbox id="project-fullscreen" value="true" name="project-fullscreen" checked={this.state.fullscreen} onChange={(e) => this.setState( {fullscreen: e.checked})} />
                        <label forhtml="project-fullscreen">{Translator.get("project_fullscreen")}</label>
                        < br />
                        <Checkbox id="project-keep-proportions" value="true" name="project-keep-proportions" checked={this.state.keepProportions} onChange={(e) => this.setState( {keepProportions: e.checked})} />
                        <label forhtml="project-keep-proportions" style={{ width: '40%' } }>{Translator.get("project_keep_proportions")}</label>
                        < br />
                        <Checkbox id="project-smoothing" value="true" name="project-smoothing" checked={this.state.smoothing} onChange={(e) => this.setState( {smoothing: e.checked})} />
                        <label forhtml="project-smoothing" style={{ width: '40%' } }>{Translator.get("project_smoothing")}</label>
                    </div>

                    <div className="project-panel-publish" style={{display: (this.state.topic==2)?'block':'none'}}>
                        <label forhtml="project-code-protect">{Translator.get("project_code_protect")}</label>
                        <SelectButton className="code-protect" id="project-code-protect" optionLabel="name" value={this.state.protect} options={[
                            { name: Translator.get('no'), value: 0 },
                            { name: Translator.get('yes'), value: 1 }
                        ]} onChange={(e) => this.setState({protect: e.target.value})} />
                    </div>
                </div>
                <div className="project-panel-footer">
                    <button onClick={ (e) => { this.hide() } }>Cancel</button>
                    <button onClick={ (e) => { this.validateProject() } }>OK</button>
                </div>
                <div className="version">{"v"+_VERSION}</div>
                </Dialog>
            </div>
        );
    }

}