import GenericUI from '../generic.ui.js'; 
import {collapseAll, expandAll, getTreeFooters, getTreeHeaders, getTreeItems, deleteItem, addItem, editItem, validateItem, selectItem} from './func/index.js';
import { Translator } from '../../translator/index.js';

export default class TreeView extends GenericUI {

    constructor( props ) {
        super( props );
        this.state = { items: props.items, selectedItem: null };
        var self = this;
    }

    componentDidMount() {
        window.TreeView = this;
    }
    
    render() {
        super.render();
        return(
            <div className="treeview">
                { getTreeHeaders( this.props ) }
                <div className="treeview-content-scroller">
                    <div className="treeview-content">
                        <ul> 
                            { getTreeItems( this.state.items, this.state.selectedItem, this.props, Translator.get('loading') ) /* FVL: the message here should be based on the current state of the "machine" are we retrieving projects/items or is the list really empty , etc */ }
                        </ul>
                    </div>
                </div>
                { getTreeFooters( this.props ) }
            </div>
        );
    }
}
