import React, {useState} from 'react';

// CSS COMMON
import "./styles/base/common.css";

// CSS BASE
import "./styles/base/desktop.css";
//import "./styles/base/smartphone.css";
//import "./styles/base/tablet.css";

// CSS UI
import "./styles/ui/desktop.css";
//import "./styles/ui/smartphone.css";
//import "./styles/ui/tablet.css";

// CSS THEME
import "./styles/themes/dark.css";

// FONTAWESOME

// TODO import only needed styles
import "./styles/fontawesome-6.6.0/css/all.min.css";
import "./styles/fontawesome-6.6.0/css/sharp-light.min.css";
import "./styles/fontawesome-6.6.0/css/sharp-thin.min.css";
import "./styles/fontawesome-6.6.0/css/sharp-solid.min.css";
import "./styles/fontawesome-6.6.0/css/sharp-regular.min.css";

import {Translator} from "./classes/translator";
import Interface from "./classes/ui/interface";
import {AuthenticationAPI} from "./api/authentication";
/**
 * The main application component.
 * @returns 
 */
export default class Application extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            context: '',
            user: AuthenticationAPI.getUserInCache(),
            token: AuthenticationAPI.getLocaleToken()
        }
    }

    componentDidMount() {
        window.Application = this;

        if( window.tryToconnectUser() )
        {
            window.tryToconnectUser();
        }
   }

    render() {
        console.log( 'context', this.state.context );
        return (
            <div className="application">
                <Interface context={this.state.context} user={this.state.user} token={this.state.token}/>
            </div>
        );
    }
}